import React from 'react';
import { imgBrand } from '../../../lib/brand';
import styles from './../styles.module.less';

const blueLine = imgBrand('blueLine.png');

interface HeaderProps {
   title: JSX.Element;
}

const Header = (props: HeaderProps) => {
   const { title } = props;
   return (
      <div className={styles.header} style={{ paddingTop: '10px', marginBottom: '20px' }}>
         <h1>{title}</h1>
         <img src={blueLine} alt="" />
      </div>
   );
};

export default Header;
