import React, { useState } from 'react';
import Header from './components/Header';
import Landing from './components/Landing';
import Footer from './components/Footer';

import styles from './styles.module.less';

interface TypesProps {
   items: number[];
   title: JSX.Element;
   images: string[];
   alt: string[];
   blueImages: string[];
   links: string[];
   gtm: string;
   staticTextItems: [];
   captions: string[];
   infos: string[];
}

const Types = (props: TypesProps) => {
   const { title, items, gtm, blueImages, infos, staticTextItems, ...rest } = props;
   const [index, setIndex] = useState(0);

   return (
      <div className={styles.Types}>
         <Header title={title} />
         <Landing updateStateProps={setIndex} items={items} gtm={gtm} blueImages={blueImages} {...rest} />
         <Footer index={index} infos={infos} staticTextItems={staticTextItems} {...rest} />
      </div>
   );
};

export default Types;
