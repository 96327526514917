import React from 'react';
import { navigate } from 'gatsby';
import StaticText from '../../StaticText';
import styles from './../styles.module.less';

interface FooterProps {
   index: number;
   images: string[];
   alt: string[];
   captions: string[];
   infos: string[];
   links: string[];
   staticTextItems: [];
}

const Footer = (props: FooterProps) => {
   const { index, images, alt, captions, infos, links, staticTextItems = [] } = props;
   return (
      <div className={styles.footerBlock} id="footer">
         <br />
         <div className={styles.footerImg}>
            <img src={images[index]} alt={alt[index]} />
         </div>
         <div className={styles.row}>
            <div className={styles.column} />
            <div className={styles.description}>
               <h2>{captions[index]}</h2>
               <div className={styles.detailsItem}>
                  <StaticText id={infos[index]} items={staticTextItems} />
               </div>
               <a href={links[index]} onClick={() => navigate(links[index])}>
                  Learn more
               </a>
            </div>
         </div>
      </div>
   );
};

export default Footer;
