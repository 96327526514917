import React, { useState } from 'react';
import styles from './../styles.module.less';

interface ImageItemProps {
   index: number;
   images: string[];
   blueImages: string[];
   alt: string[];
   captions: string[];
   links: string[];
   isActive: boolean;
   onClick: (index: number) => void;
   gtm: string;
}

const ImageItem = (props: ImageItemProps) => {
   const { index, images, blueImages, alt, captions, links, isActive, gtm, onClick } = props;
   const [isHovering, setIsHovering] = useState(false);

   const onClicked = (index: number) => {
      onClick(index);
   };

   const handleMouseHover = () => {
      setIsHovering(!isHovering);
   };

   return (
      <div className={styles.iconBlock} onMouseEnter={handleMouseHover} onMouseLeave={handleMouseHover}>
         <div className={styles.desc}>
            <div className={styles.icon}>
               <img
                  src={isActive ? images[index] : blueImages[index] && isHovering ? images[index] : blueImages[index]}
                  onClick={() => onClicked(index)}
                  alt={alt[index]}
                  data-gtm={gtm}
               />
            </div>
         </div>
         <div className={styles.mob}>
            <a className={styles.mobileLink} href={links[index]}>
               <div className={styles.icon}>
                  <img src={blueImages[index]} onClick={() => onClicked(index)} alt="" />
               </div>

               <h4 className={styles.mobileDesc}>{captions[index]}</h4>
            </a>
         </div>
      </div>
   );
};

export default ImageItem;
