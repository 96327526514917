import React, { useState } from 'react';
import Item from './Item';
import styles from './../styles.module.less';

interface LandingProps {
   items: number[];
   images: string[];
   blueImages: string[];
   gtm: string;
   updateStateProps: (index: number) => void;
   alt: string[];
   captions: string[];
   links: string[];
}

const Landing = (props: LandingProps) => {
   const { items, gtm, updateStateProps, images, blueImages, alt, captions, links } = props;
   const [index, setIndex] = useState(0);

   const onClicked = (i: number) => {
      setIndex(i);
      updateStateProps(i);
      //scroll to show loan description
      const footer = document.getElementById('footer');
      const scrollIntoViewOptions: boolean | ScrollIntoViewOptions | undefined = {
         behavior: 'smooth',
         block: 'center',
         inline: 'nearest'
      };
      if (footer) footer.scrollIntoView(scrollIntoViewOptions);
   };

   return (
      <div>
         <div className={styles.cloud}>
            <div className={styles.row}>
               {items.map(i => {
                  return (
                     <div className={styles.item} key={i} data-gtm={gtm}>
                        <Item
                           index={i}
                           isActive={i === index}
                           onClick={onClicked}
                           images={images}
                           blueImages={blueImages}
                           alt={alt}
                           captions={captions}
                           links={links}
                           gtm={gtm}
                        />
                     </div>
                  );
               })}
            </div>
         </div>
      </div>
   );
};

export default Landing;
