import React, { Component } from 'react';
import styles from './styles.module.less';
import { img } from '../../../lib/brand';

var background = img('boots-outside.jpg');

class HomeLoansMid extends Component {
   constructor(props) {
      super(props);
      this.backRef = React.createRef();
   }

   render() {
      let backVideo = 'https://ucarecdn.com/1e79f8ae-1ede-4fe3-bf9c-4c3563017455/bootsOutside.mp4';

      return (
         <section className={styles.HeroCinemaTwo}>
            <div className={styles.bgVideo}>
               <video className={styles.back} ref={this.backRef} loop autoPlay muted playsInline>
                  <source src={backVideo} type="video/mp4" />
                  <source src={backVideo} type="video/ogg" />
                  <img src={background} title="Your browser does not support the video tag." alt="" />
               </video>
            </div>
         </section>
      );
   }
}

export default HomeLoansMid;
