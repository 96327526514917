import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { mapBlogs } from '../../lib/utility';

import Layout from '../../components/Layout';
import Header from '../../components/Header';
import HeroLarge from '../../components/HeroLarge';
import IntroBlock from '../../components/IntroBlock';
import Types from '../../components/Types';
import BlogRoll from '../../components/BlogRoll';
import FindLO from '../../components/FindLO';
import Calculators from '../../components/Calculators';
import { homeLoanTypeIcons } from '../../lib/gtm';
import HomeLoansMid from '../../components/cinemagraphs/HomeLoansMid';
import { img, imgBrand, isBe, alt as altText } from '../../lib/brand';
import styles from './styles.module.less';

// Home loan types icons
// active
var icons_DownpAssist_active = imgBrand('typesImg/Icons_DownpAssist_active.png');
var icons_FHA_active = imgBrand('typesImg/Icons_FHA_active.png');
var icons_FixedRate_active = imgBrand('typesImg/Icons_FixedRate_active.png');
var icons_Jumbo_active = imgBrand('typesImg/Icons_Jumbo_active.png');
var icons_VA_active = imgBrand('typesImg/Icons_VA_active.png');
var icons_AdjustableRate_active = imgBrand('typesImg/Icons_AdjustableRate_active.png');
var icons_USDA_active = imgBrand('typesImg/Icons_USDA_active.png');
// inactive
var icons_DownpAssist_inactive = imgBrand('typesImg/Icons_DownpAssist_inactive.png');
var icons_FHA_inactive = imgBrand('typesImg/Icons_FHA_inactive.png');
var icons_FixedRate_inactive = imgBrand('typesImg/Icons_FixedRate_inactive.png');
var icons_Jumbo_inactive = imgBrand('typesImg/Icons_Jumbo_inactive.png');
var icons_VA_inactive = imgBrand('typesImg/Icons_VA_inactive.png');
var icons_AdjustableRate_inactive = imgBrand('typesImg/Icons_AdjustableRate_inactive.png');
var icons_USDA_inactive = imgBrand('typesImg/Icons_USDA_inactive.png');

// bgimages
var hero = img('hero.jpg', 'home-loans');

const HomeLoans = ({
   staticTextItems,
   items = [0, 1, 2, 3, 4, 5, 6],
   images = [
      icons_FixedRate_active,
      icons_AdjustableRate_active,
      icons_FHA_active,
      icons_VA_active,
      icons_Jumbo_active,
      icons_DownpAssist_active,
      icons_USDA_active
   ],
   blueImages = [
      icons_FixedRate_inactive,
      icons_AdjustableRate_inactive,
      icons_FHA_inactive,
      icons_VA_inactive,
      icons_Jumbo_inactive,
      icons_DownpAssist_inactive,
      icons_USDA_inactive
   ],

   captions = [
      'Fixed-Rate Mortgage',
      'Adjustable-Rate Mortgage',
      'FHA Loans',
      'VA Loans',
      'Jumbo Loans',
      'Down Payment Assistance Programs',
      'USDA Loans'
   ],

   infos = [
      'home-loans-fixed-rate-info',
      'home-loans-adjustable-rate-info',
      'home-loans-fha-info',
      'home-loans-va-info',
      'home-loans-jumbo-info',
      'home-loans-dpa-info',
      'home-loans-usda-info'
   ],
   links = [
      '/fixed-rate-mortgage',
      '/adjustable-rate-mortgage',
      '/fha-loan',
      '/va-loans',
      '/jumbo-loan',
      '/down-payment-assistance-programs',
      '/usda-home-loan'
   ],
   alt = [
      'Fixed Rate Mortgage Icon - Home Loans',
      'Adjustable Rate Mortgage Icon - Home Loans',
      'FHA Loan Icon - Home Loans',
      'VA Loan Icon - Home Loans',
      'Jumbo Loan Icon - Home Loans',
      'Down Payment Assistance Program Icon - Home Loans',
      'USDA Loan Program Icon - Home Loans'
   ].map(altText)
}) => {
   const data = useStaticQuery(graphql`
      query HomeLoansPageQuery {
         corpBlogs: allBlog(
            sort: { fields: [date], order: DESC }
            filter: { type: { eq: "corporate" }, tags: { in: ["Home Loan"] } }
         ) {
            edges {
               node {
                  id
                  html
                  slug
                  title
                  date
                  feature
                  categories
                  image
                  author {
                     slug
                     profile {
                        name
                     }
                     gallery {
                        photo
                     }
                  }
                  date
                  feature
               }
            }
         }
      }
   `);
   const intro = 'What is a home loan?';
   const par = (
      <>
         For many it’s the most important financial decision they will make. A home loan not only lets you get into a
         house for you or your family—it’s also an essential part of your financial security. When your home appreciates
         in value it adds to your net worth. Many homeowners will later refinance their property to take cash out.
      </>
   );

   const { corpBlogs } = data;
   const { edges: corpBlobPosts = [] } = corpBlogs || {};
   const corporateBlogs = mapBlogs(corpBlobPosts);

   const props = {
      items,
      images,
      blueImages,
      links,
      captions,
      infos,
      title: <span>Know the types of home&nbsp;loans</span>,
      gtm: homeLoanTypeIcons,
      alt,
      staticTextItems
   };

   const header = <Header active={['current-homeowner', 'home-loans']} />;

   return (
      <Layout hasHero={true} header={header}>
         <Helmet>
            <title>{altText('Home Loan - Home Mortgage')}</title>
            <meta
               name="description"
               content="A home loan is a financial transaction in which a lender advances funds to a borrower to assist them in buying a house. Interested in getting a home loan? Contact us today."
            />
            <script
               src={`https://embed.signalintent.com/js/embedded.js?org-guid=${process.env.GATSBY_SIGNAL_INTENT_ORG_GUID}`}></script>
         </Helmet>
         <div className={styles.HomeLoans}>
            <HeroLarge customPhoto={hero} className={styles.HomeHero} />
            <IntroBlock header={intro} par={par} />
            <Types {...props} />
            <HomeLoansMid />
            {isBe && (
               <>
                  <Calculators
                     showCalculatorMortgage={true}
                     showCalculatorRentVsBuy={true}
                     showCalculatorHomeAffordability={true}
                     showCalculatorRequiredIncome={true}
                  />
                  <FindLO />
                  {corporateBlogs.length >= 3 && (
                     <BlogRoll
                        corporateBlogs={corporateBlogs}
                        blogs={corporateBlogs}
                        showBlogs={true}
                        word="our"
                        url="home-loans"
                     />
                  )}
               </>
            )}
         </div>
      </Layout>
   );
};
export default HomeLoans;
